html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.multiTargetConfig p[class*="RaSimpleFormIterator-index"] {
  font-size: 2rem;
  font-weight: 600;
  color: #4f3cc9;
}
.darkTheme.multiTargetConfig p[class*="RaSimpleFormIterator-index"] {
  color: #ffffff;
}
.multiTargetConfig div:has(.ra-input) p[class*="RaSimpleFormIterator-index"] {
  font-size: unset;
  font-weight: unset;
  color: unset;
}
